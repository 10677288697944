<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item   label="小区" prop="villageId">
        <a-select
          v-model="form.villageId"
          showSearch
          style="width: 100%"
          placeholder="请选择小区"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in listAllStore">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="公告类型" prop="type" >
        <a-select placeholder="请选择类型"   v-model="form.type">
          <a-select-option :value="1">图文公告</a-select-option>
          <a-select-option :value="2">投票公告</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if='form.type == 2' label="投票帖子开始时间" prop="voteStartTime" >
        <a-date-picker   format='YYYY-MM-DD' v-model="form.voteStartTime" />
      </a-form-model-item>
      <a-form-model-item v-if='form.type == 2'  label="投票帖子结束时间" prop="voteEndTime" >
        <a-date-picker   format='YYYY-MM-DD' v-model="form.voteEndTime" />
      </a-form-model-item>
      <a-form-model-item v-if='form.type == 2'  label="投票选项（最多五个）" prop="options" >
<!--        <a-input v-model="form.name" placeholder="请输入投票选项名称" style="width: 70%"/>-->
        <p><a-button  type="primary" :disabled="this.form.optionsList.length >= 5" style="margin-left: 10px" @click="addGG()">添加选项</a-button></p>
        <span v-for="(option,index) in form.optionsList">
          <a-input v-model="option.name" placeholder="请输入投票选项名称" style="width: 70%"/>
          <a-button type="text"  size="small" style="margin-left: 10px" @click="delGG(index)">-</a-button>
        </span>

      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor editor-id='contentZh' v-model="form.content" />
      </a-form-model-item>


<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMessage, addMessage, updateMessage } from '@/api/message/villageMessage'
import Editor from '@/components/Editor'
import { listVillage,  } from '@/api/village/village'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      listAllStore: [],
      // 表单参数
      form: {
        id: null,

        villageId: null,

        type: null,

        title: null,

        content: null,

        imgUrl: null,

        voteStartTime: null,

        voteEndTime: null,

        createTime: null,
        optionsList:[],
        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          {required: true, message: '标题不能为空', trigger: 'blur'}
        ],
        villageId: [
          {required: true, message: '小区不能为空', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '类型不能为空', trigger: 'blur'}
        ],

        voteStartTime: [
          {required: true, message: '投票开始时间不能为空', trigger: 'blur'}
        ],
        voteEndTime: [
          {required: true, message: '投票结束时间不能为空', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '内容不能为空', trigger: 'blur'}
        ],

      }
    }
  },
  filters: {
  },
  created () {
    this.getStore();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    addGG(){
      console.log(this.form)
      if(this.form.optionsList.length < 5){
        this.form.optionsList.push({})
      }

    },
    delGG(index){
      this.form.optionsList.splice(index, 1)
    },
    //获取门店
    getStore(){
      listVillage({}).then(res => {
        this.listAllStore = res.data;
      })
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        villageId: null,
        type: null,
        title: null,
        content: null,
        imgUrl: null,
        voteStartTime: null,
        voteEndTime: null,
        createTime: null,
        optionsList:[],
        remark: null
      }

      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()

      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessage({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMessage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMessage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
