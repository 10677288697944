import request from '@/utils/request'


// 查询小区通知列表
export function listMessage(query) {
  return request({
    url: '/user/village-messages/list',
    method: 'get',
    params: query
  })
}

// 查询小区通知分页
export function pageMessage(query) {
  return request({
    url: '/user/village-messages/page',
    method: 'get',
    params: query
  })
}

// 查询小区通知详细
export function getMessage(data) {
  return request({
    url: '/user/village-messages/detail',
    method: 'get',
    params: data
  })
}

// 新增小区通知
export function addMessage(data) {
  return request({
    url: '/user/village-messages/add',
    method: 'post',
    data: data
  })
}

// 修改小区通知
export function updateMessage(data) {
  return request({
    url: '/user/village-messages/edit',
    method: 'post',
    data: data
  })
}

// 删除小区通知
export function delMessage(data) {
  return request({
    url: '/user/village-messages/delete',
    method: 'post',
    data: data
  })
}
export function changeIsHome(data) {
  return request({
    url: '/user/village-messages/changeIsHome',
    method: 'post',
    data: data
  })
}