import request from '@/utils/request'


// 查询小区列表
export function listVillage(query) {
  return request({
    url: '/user/villages/list',
    method: 'get',
    params: query
  })
}

// 查询小区分页
export function pageVillage(query) {
  return request({
    url: '/user/villages/page',
    method: 'get',
    params: query
  })
}

// 查询小区详细
export function getVillage(data) {
  return request({
    url: '/user/villages/detail',
    method: 'get',
    params: data
  })
}

// 新增小区
export function addVillage(data) {
  return request({
    url: '/user/villages/add',
    method: 'post',
    data: data
  })
}

// 修改小区
export function updateVillage(data) {
  return request({
    url: '/user/villages/edit',
    method: 'post',
    data: data
  })
}

// 删除小区
export function delVillage(data) {
  return request({
    url: '/user/villages/delete',
    method: 'post',
    data: data
  })
}
